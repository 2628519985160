<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="color: #fff">运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>服务通知</el-breadcrumb-item>
      <el-breadcrumb-item>服务通知类型</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card style="height: 680px; overflow: auto">
      <el-row style="margin-bottom: 15px">
        <el-button @click="adddialogVisiblie = true" size="mini" type="primary">新增服务通知类型</el-button>
      </el-row>
      <!-- 分类搜索 -->
      <el-row>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" @input="keyword" placeholder="类型" v-model="queryInfo.keyword">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--商品数据展示区域-->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 800px"
        
        ref="singleTable"
        highlight-current-row
        :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
        row-key="id"
        stripe
      >
      <el-table-column type="selection" width="50"/>
        <el-table-column prop="id" label="序号" width="80">
          <template> </template>
        </el-table-column>
             <el-table-column prop="typeName" label="业务" width="100" show-overflow-tooltip>
              <template  slot-scope="scope">
              <p v-if="scope.row.businessType==1">电商</p>
              <p v-else-if="scope.row.businessType==2">代驾</p>
              <p v-else-if="scope.row.businessType==3">其他</p>
              </template>
        </el-table-column>
        <el-table-column prop="typeName" label="类型" width="250" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="nam" label="图标" width="100" show-overflow-tooltip>
        <template  slot-scope="scope">
        <img style="width: 50px;height: 50px;" :src="scope.row.logo" alt="">
        </template>
        </el-table-column>
        <el-table-column label="操作" min-width="200" fixed="right">
        <template  slot-scope="scope">
          <el-button  @click="compileType(scope.row.id)" size="mini" type="text">编辑</el-button>
          <el-popconfirm @confirm="deleter(scope.row.id)" style="padding-left: 20px;" title="确定删除此类型吗？">
          <el-button  slot="reference" size="mini" type="text">删除</el-button>
          </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
       <!--添加服务通知类型  -->
      <el-dialog
        width="30%"
        title="添加服务通知类型"
        :append-to-body="true"
        :visible.sync="adddialogVisiblie"
      >
        <el-form :model="addTypedate">
        <el-form-item label="业务" required >
      <el-radio-group v-model="addTypedate.businessType">
    <el-radio :label="1">电商</el-radio>
    <el-radio :label="2">代驾</el-radio>
  </el-radio-group>
        </el-form-item>
          <el-form-item label="类型" required>
            <el-input style="width: 60%" size="mini" v-model="addTypedate.typeName"></el-input>
          </el-form-item>
          <el-form-item label="图标" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upLoadShopLogo"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
           
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog  :append-to-body="true" :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
        </el-form>
        <span slot="footer"  class="dialog-footer">
          <el-button type="danger" @click="adddialogVisiblier()">取消</el-button>
          <el-button type="primary" @click="addMerchandiseType()">确定</el-button>
        </span>
      </el-dialog>
  
       <!--编辑服务通知类型  -->
      <el-dialog
        width="30%"
        title="编辑服务通知类型"
        :append-to-body="true"
        :visible.sync="dialogVisiblie"
        
      >
        <el-form :model="compileTypeData">
        <el-form-item label="业务" required >
      <el-radio-group v-model="compileTypeData.businessType">
    <el-radio :label="1">电商</el-radio>
    <el-radio :label="2">代驾</el-radio>
  </el-radio-group>
        </el-form-item>
          <el-form-item label="类型" required>
            <el-input style="width: 60%" size="mini" v-model="compileTypeData.typeName"></el-input>
          </el-form-item>
          <el-form-item label="图标" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upLoadShopLogo2"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview2"
              :before-upload="beforeAvatarUpload2"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog append-to-body :visible.sync="dialogVisible2">
              <img width="100%" :src="dialogImageUrl2" alt="" />
            </el-dialog>
           <template>
            <img   :src="logo" alt="" @click="ondialogimgsrc(logo)" width="150px" style="margin: 15px 15px 0px 50px" >
            </template>
          </el-form-item>
        </el-form>
        <span slot="footer"  class="dialog-footer">
          <el-button type="danger" @click="dialogVisiblie = false">取消</el-button>
          <el-button type="primary" @click="compilechandiseType()">确定</el-button>
        </span>
      </el-dialog>
            <!-- 列表大图展示 -->
     <el-dialog :visible.sync="dialogimg"  :append-to-body="true" >
              <img width="100%" :src="dialogimgsrc" alt="" />
            </el-dialog>
  
    </el-card>
  </div>
</template>

<script>
// 引入拖动列表组件

import dataFunction from "@/utils/dataFunction";
import { searchSystemMessageTypeList,insertSystemMessageType,uploadImages,delSystemMessageType,searchSystemMessageTypeById,updateSystemMessageType } from "@/api";

export default {
  name: "UsesignList",
  data() {
    return {
      dialogimg:false,
      dialogimgsrc:"",
      dialogImageUrl2:"",
      dialogVisible2:false,
      compileTypeData: [],
      dialogVisible:false,
      dialogImageUrl:"",

            // 添加服务通知类型
        adddialogVisiblie: false,
      // 编辑服务通知类型
      dialogVisiblie: false,
      // 弹窗页面设置
      setType: 0,
       loading: false,
      // 新建区域弹窗
      innerVisible: false,
      form: {},
      fileList: [],
      outerVisible: false,
      radio: "1",    
      logo:"",  
      // 添加类型参数
      addTypedate:{
    businessType: 1,
     logo: "",
      },
      aop:"",
      // 查找类型列表参数对象
      queryInfo: {
        page: 1,
        endDate: 10,
        keyword: "",
        size:10,
        startDate:0
      },
      time: "",
      total: 0,
      tableData: [],
    }
  },
  created() {
    this.getGoodsList();
  },
  methods: {
     ondialogimgsrc(data){
this.dialogimgsrc=data
this.dialogimg=true
    },
    beforeAvatarUpload2(){
this.compileTypeData.logo=""
    },
     handlePictureCardPreview2(file) {
    
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    // 编辑图标
      async upLoadShopLogo2(file) {
   if(["image/gif","image.webp"].indexOf(file.file.type)==0) return this.$message.error("图片格式不支持")
       const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.compileTypeData.logo = res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    adddialogVisiblier(){
this.adddialogVisiblie=false
 this.addTypedate={ businessType: 1, logo: "",
     }
    },
    // 确认编辑弹窗类型
   async compilechandiseType(){
  if(!this.compileTypeData.typeName) return this.$message.error("请上传类型名称")
  if(!this.compileTypeData.logo) return this.$message.error("请上传图标")
  const { data: res } = await updateSystemMessageType(this.compileTypeData)
   if (res.code !== 200) return this.$message.error("更改失败,请稍后重试");
   this.compileTypeData=[]
   this.dialogVisiblie=false
      this.loading=false
      this.$message.success('更改成功')
   this.getGoodsList()
    },
    // 打开编辑类型弹窗
 async compileType(id){
const { data: res } = await searchSystemMessageTypeById({id:id})
this.compileTypeData=res.body
this.logo=res.body.logo
this.dialogVisiblie=true
 },
// 删除类型
async deleter(id){
  const formData = new FormData()
  formData.append("ids", id);
  const { data: res } = await delSystemMessageType(formData)
  if (res.code !== 200) return this.$message.error("删除失败请稍后重试");
     this.loading=false
     this.$message.success('删除成功')
  this.getGoodsList()
},

  //  模糊查询
 keyword(){
    this.queryInfo.page =1
       this.loading=false
    this.getGoodsList()
  },
      // 上传服务通知类型
  async addMerchandiseType(){
     if(!this.addTypedate.typeName) return this.$message.error("请上传类型名称")
     if(!this.addTypedate.logo) return this.$message.error("请上传图标")
       const { data: res }=await insertSystemMessageType( this.addTypedate);  
     if (res.code !== 200) return this.$message.error(res.msg);
        this.adddialogVisiblie=false
        this.addTypedate={
      businessType: 1,
     logo: "",
     }
     this.$message.success('上传成功')
        this.loading=false
        this.getGoodsList()
      },
        // 点击文件列表中已上传的文件时的钩子
 handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

// 图标上传
  beforeAvatarUpload(){

  },
  async upLoadShopLogo(file) {
   if(["image/gif","image.webp"].indexOf(file.file.type)==0) return this.$message.error("图片格式不支持")
       const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.addTypedate.logo = res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    /*
     *
     * 默认请求第一页 十条数据
     * */
  async getGoodsList() {
        const { data: res } = await searchSystemMessageTypeList( this.queryInfo);
        if (res.code !== 200) return this.$message.error("服务通知列表错误");
        this.tableData = res.body.list;
        this.total = res.body.total;
        this.loading=false
    },
 handleSizeChange(data){
    this.queryInfo.page =1
    this.queryInfo.size = data;
       this.loading=false
    this.getGoodsList();
},
    handleCurrentChange(data) {
      this.queryInfo.page = data;
         this.loading=false
      this.getGoodsList();
    },
    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style scoped lang="scss">
.mr15 {
  margin-right: 15px;
}

p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }

    img {
      display: block;

      width: 100px;
    }
  }
}
.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;
  
}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mr15 {
  margin-right: 15px;
}
</style>